// Libs.
import React from 'react';
// Assets.
import styles from './advice-bordered.module.scss';
import LongosIcon from '../../../images/icons/icon-longos.svg';
import * as PropTypes from 'prop-types';

const AdviceBordered = ({ data }) => {
  return (
    <div className={styles.adviceBordered}>
      <div className={styles.containeredHeadingImage}>
        <img src={LongosIcon} alt="Logo" />
      </div>
      <div className={styles.adviceBorderedContent}>
        {data.header && <h3 className={styles.adviceBorderedHeader}>{data.header}</h3>}
        {data.summary && <div className={styles.adviceBorderedSummary}>{data.summary}</div>}
      </div>
    </div>
  );
};

AdviceBordered.propTypes = {
  data: PropTypes.shape({
    header: PropTypes.string,
    summary: PropTypes.string,
  }),
};

export default AdviceBordered;
