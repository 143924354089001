// Libs.
import React from 'react';
import * as PropTypes from 'prop-types';
//Components.
import SetInnerHtml from '../common/set-inner-html/set-inner-html';
import { formatRecipeHeaders } from './format-recipe-headers';
// Assets.
import styles from './details-hero.module.scss';
import locale from '../../locale/locale';

const RecipeIngredients = ({ recipe: { ingredients = '' } }) => {
  if (!ingredients) {
    return null;
  }

  const data = {
    header: locale.recipe.ingredients,
    content: ingredients
      .split('\n')
      .map((lineItem, i) => formatRecipeHeaders(lineItem, i, (line) => <p key={i}>{line}</p>)),
  };

  return (
    <div className={styles.detailsHero}>
      <SetInnerHtml data={data} />
    </div>
  );
};

RecipeIngredients.propTypes = {
  recipe: PropTypes.shape({
    ingredients: PropTypes.string,
  }),
};

export default RecipeIngredients;
