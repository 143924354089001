// Libs.
import React from 'react';
import { compact } from 'lodash';
// Assets.
import styles from './recipe-nutritional.module.scss';
//Components.
import * as PropTypes from 'prop-types';
import locale from '../../locale/locale';

const RecipeNutritional = (
  {
    recipe: {
      nutritional = '', protein = 0, fibre = 0, fat = 0, sugar = 0, carbs = 0, sodium = 0,
    },
  }) => {
  if (!nutritional) {
    return null;
  }

  const header = `${String(nutritional || '').split(';')[0]}`;

  const items = compact([
    `Protein ${protein || 0}g`,
    `Fibre ${fibre || 0}g`,
    `Fat ${fat || 0}g`,
    `Sugar ${sugar || 0}g`,
    `Carbs ${carbs || 0}g`,
    `Sodium ${sodium || 0}mg`,
  ]);

  return (
    <div className={styles.recipeNutritional}>
      <h3 className={styles.recipeNutritionalHeader}>{locale.recipe.nutritionalInformation}</h3>
      <div className={styles.recipeNutritionalContent}>
        <div className={styles.recipeNutritionalSummary}>
          <span>{header}</span>
        </div>
        <ul className={styles.recipeNutritionalItems}>
          {items.map((item, i) => (
            <li className={styles.recipeNutritionalItem} key={i}>
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

RecipeNutritional.propTypes = {
  recipe: PropTypes.shape({
    nutritional: PropTypes.string,
    calories: PropTypes.string,
    protein: PropTypes.string,
    fibre: PropTypes.string,
    fat: PropTypes.string,
    sugar: PropTypes.string,
    carbs: PropTypes.string,
    sodium: PropTypes.string,
  }),
};

export default RecipeNutritional;
