// Libs.
import React from 'react';
import * as PropTypes from 'prop-types';
// Components.
import DetailsHero from './details-hero';
import RecipeIngredients from './recipe-ingredients';
import RecipeDirections from './recipe-directions';
import RecipeAdvice from './recipe-advice';
import RecipeNutritional from './recipe-nutritional';
// Assets.
import styles from './recipe-details-page.module.scss';

const RecipeDetailsPage = ({ recipe }) => {
  return (
    <div className={styles.recipeDetailsLanding}>
      <div className={styles.recipeDetailsContainer}>
        <DetailsHero recipe={recipe}/>
        <div className={styles.recipeDetailsContent}>
          <div className={styles.recipeDetailsSideLeft}>
            <RecipeIngredients recipe={recipe}/>
          </div>
          <div className={styles.recipeDetailsSideRight}>
            <RecipeDirections recipe={recipe}/>
            {String(recipe.advice || '') && recipe.advice.length > 0 && <RecipeAdvice data={recipe}/>}
          </div>
        </div>
        <RecipeNutritional recipe={recipe}/>
      </div>
    </div>
  );
};

RecipeDetailsPage.propTypes = {
  recipe: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.object,
    prep: PropTypes.string,
    total: PropTypes.string,
    cook: PropTypes.string,
    serves: PropTypes.string,
    ingredients: PropTypes.string,
    instructions: PropTypes.string,
    advice: PropTypes.string,
    description: PropTypes.string,
    protein: PropTypes.string,
    fat: PropTypes.string,
    carbs: PropTypes.string,
    fibre: PropTypes.string,
    sugar: PropTypes.string,
    sodium: PropTypes.string,
  }),
};

export default RecipeDetailsPage;
