// Libs.
import React from 'react';
import * as PropTypes from 'prop-types';
//Components.
import ContaineredHeading from '../common/heading-containered/containered-heading';
// Assets.
import styles from './details-hero.module.scss';
import locale from '../../locale/locale';

const DetailsHero = ({ recipe: { title, prep = '', total = '', serves = '', image = '' } }) => {
  let subTitle = String(serves || '');

  // If keywords missing on server label, add it here.
  if (subTitle.match(/(Serves|Yield)/i) === null) {
    subTitle = `Serves ${subTitle}`;
  }

  const content = {
    title,
    subTitle,
    image,
    tag: locale.recipe.tag,
    summary: [],
  };

  if (prep) {
    content.summary.push({
      header: locale.recipe.prep,
      description: prep,
    });
  }
  if (total) {
    content.summary.push({
      header: locale.recipe.totalTime,
      description: total,
    });
  }

  return (
    <div className={styles.detailsHero}>
      <ContaineredHeading data={content}/>
    </div>
  );
};

DetailsHero.propTypes = {
  recipe: PropTypes.shape({
    prep: PropTypes.string,
    total: PropTypes.string,
    serves: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.object,
  }),
};

export default DetailsHero;
