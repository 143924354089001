// Libs.
import React from 'react';
import { isString } from 'lodash';
import * as PropTypes from 'prop-types';
//Components.
import SetInnerHtml from '../common/set-inner-html/set-inner-html';
import { formatRecipeHeaders } from './format-recipe-headers';
// Assets.
import styles from './details-hero.module.scss';
import locale from '../../locale/locale';

const RecipeDirections = ({ recipe: { directions = '' } }) => {
  if (!directions) {
    return null;
  }

  const data = {
    header: locale.recipe.directions,
    content: (
      <ul className={styles.recipeDirectionsList}>
        {directions
          .split('\n')
          .filter((lineItem) => isString(lineItem) && lineItem.length > 2 && lineItem !== '\n')
          .map((lineItem, i) =>
            formatRecipeHeaders(lineItem, i, (line) => (
              <li key={i}>
                <span>&bull;</span>
                <span>{line}</span>
              </li>
            ))
          )}
      </ul>
    ),
  };

  return (
    <div className={styles.recipeDirections}>
      <SetInnerHtml data={data} />
    </div>
  );
};

RecipeDirections.propTypes = {
  recipe: PropTypes.shape({
    directions: PropTypes.string,
  }),
};

export default RecipeDirections;
