// Libs.
import React from 'react';
// Assets.
import styles from './containered-heading.module.scss';
import Img from 'gatsby-image';
import * as PropTypes from 'prop-types';
import { has } from 'lodash';
import imgFallback from '../../../images/class_hero_image.png';

const ContaineredHeading = ({ data }) => {

  return (
    <div className={styles.containeredHeading}>
      {data.tag && <p className={styles.containeredHeadingTag}>{data.tag}</p>}
      {data.title && <h1 className={styles.containeredHeadingTitle}>{data.title}</h1>}
      <div className={styles.containeredHeadingImage}>
        {has(data.image, 'fluid') ?
          <Img fluid={data.image.fluid}/> :
          <img src={imgFallback} alt="Placeholder"/>
        }
      </div>
      <div className={styles.containeredHeadingContent}>
        <div className={styles.containeredHeadingContentLeft}>
          {data.summary &&
          data.summary.map((item, i) => {
            return (
              <div key={i} className={styles.containeredHeadingPrep}>
                {item.header && (
                  <span className={styles.containeredHeadingHeader}>{item.header}</span>
                )}
                {':'}
                {item.description && (
                  <span className={styles.containeredHeadingDescription}>{item.description}</span>
                )}
              </div>
            );
          })}
        </div>
        <div className={styles.containeredHeadingContentRight}>
          {data.subTitle && <p className={styles.containeredHeadingSubTitle}>{data.subTitle}</p>}
        </div>
      </div>
    </div>
  );
};

ContaineredHeading.propTypes = {
  data: PropTypes.shape({
    tag: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.object.isRequired,
    summary: PropTypes.arrayOf(
      PropTypes.shape({
        header: PropTypes.string,
        description: PropTypes.string,
      }),
    ),
  }),
};

export default ContaineredHeading;
