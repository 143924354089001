// Libs
import React from 'react';
import { has, isEmpty } from 'lodash';
// Deps
import Layout from '../../components/layout/layout';
import RecipeDetailsPage from '../../components/recipe-details/recipe-details-page';
import SEO from '../../components/layout/seo';
import {useSiteConfigStaticQuery} from "../../hooks/useSiteConfigStaticQuery";

const RecipePage = ({ pageContext }) => {
  const { recipe } = pageContext;
  const search = useSiteConfigStaticQuery()['siteMetadata']['apiUrl'];
  let metatag = [];
  let ogTitle = recipe.title;
  let ogDescription = recipe.description;
  if (has(recipe.metatag, 'og_title')) {
    ogTitle = recipe.metatag.og_title;
  }
  if (has(recipe.metatag, 'og_description')) {
    ogDescription = recipe.metatag.og_description;
  }
  ['og:image', 'og:image:url'].forEach((item) => {
    if (has(recipe, 'image.fluid.src') && !isEmpty(recipe.image.fluid.src)) {
      metatag.push({
        property: item,
        content: `${search}${recipe.image.fluid.src}`,
      });
    }
  });
  if (has(recipe, 'field_file_image.alt') && !isEmpty(recipe.field_file_image.alt)) {
    metatag.push({
      property: 'og:image:alt',
      content: recipe.field_file_image.alt,
    });
  }
  if (has(recipe, 'field_file_image.width') && !isEmpty(recipe.field_file_image.width)) {
    metatag.push({
      property: 'og:image:width',
      content: recipe.field_file_image.width,
    });
  }
  if (has(recipe, 'field_file_image.height') && !isEmpty(recipe.field_file_image.height)) {
    metatag.push({
      property: 'og:image:height',
      content: recipe.field_file_image.height,
    });
  }
  if (has(recipe, 'alias') && !isEmpty(recipe.alias)) {
    metatag.push({
      property: 'og:url',
      content: `${search}/${recipe.alias}`,
    });
  }

  return (
    <Layout>
      <SEO title={ogTitle} description={ogDescription} meta={metatag} />
      <RecipeDetailsPage recipe={recipe} />
    </Layout>
  );
};

export default RecipePage;
