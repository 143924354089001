// Libs.
import React from 'react';
// Assets.
import styles from './details-hero.module.scss';
import locale from '../../locale/locale';
//Components.
import AdviceBordered from '../common/advice-bordered/advice-bordered';
import * as PropTypes from 'prop-types';

const RecipeAdvice = ({ data: { advice = '' } }) => {
  if (!advice) {
    return null;
  }

  const content = {
    header: locale.recipe.dashOfAdvice,
    summary: advice,
  };

  return (
    <div className={styles.recipeAdvice}>
      <AdviceBordered data={content}/>
    </div>
  );
};

RecipeAdvice.propTypes = {
  data: PropTypes.shape({
    header: PropTypes.string,
    content: PropTypes.string,
    advice: PropTypes.string,
  }),
};

export default RecipeAdvice;
