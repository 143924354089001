import React from 'react';
import { trimStart } from 'lodash';
import ingredientsStyles from './recipe-ingredients.module.scss';

export const formatRecipeHeaders = (lineItem, i, defaultRender) => {
  const [line] = trimStart(lineItem.trim(), ',').split(':');
  if (lineItem.indexOf(':') > 0) {
    return (
      <div key={i} className={ingredientsStyles.recipeIngredientsInnerHeading}>
        {line}
      </div>
    );
  } else {
    return defaultRender(line);
  }
};
